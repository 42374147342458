import {animateInViewport} from "../transition";
import {windowSize} from "../layout";

$( '.image-carousel' ).each( function() {
	var $this = $( this );

	var swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		grabCursor: true,
		slidesPerView: 1.1,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 1.3,
				spaceBetween: 15,
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );

	let tabElements = $this.parents( '.carousel-container' ).find( '.swiper-tab' );

	tabElements.each( function( index ) {
		$( this ).on( 'click', function() {
			swiper.slideTo( index );
			tabElements.removeClass( 'active' );
			$( this ).addClass( 'active' );
		} );
	} );
} );
